<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div >
    <v-container fluid style="padding-bottom: 100px">
      <v-row style="margin-top: -20px" dense>
        <v-col>
          <v-breadcrumbs :items="items" light>
            <template v-slot:divider>
              <v-icon small>$vuetify.icons.values.mdiChevronRight</v-icon>
            </template>
          </v-breadcrumbs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="3">
          <span><v-icon large class="mr-3">$vuetify.icons.values.mdiChartBar</v-icon></span>
          <span>{{this.$store.state.home.user.dashboardLabel}}</span>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="6" class="text-center text-sm-left text-md-left text-lg-left text-xl-left">
          <v-row>
            <v-col cols="12" sm="12" md="4" lg="4" >
              <v-select v-model="filterBox.assessment" :items="assessmentDropdown" class="assessment-selector"
                        :label="$t('msg.assessmentDropDownLabelHome')" chips deletable-chips small-chips solo>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" >
              <v-select v-model="filterBox.organization" :items="organizationDropdown" class="assessment-selector"
                        :label="$t('msg.clientDropDownLabelHome')" chips deletable-chips small-chips solo>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" >
              <v-btn color="#5660a5" class="d-inline mt-2" dark @click="filter">
                <v-icon>$vuetify.icons.values.mdiArrowRightDropCircleOutline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center text-sm-left text-md-left text-lg-left text-xl-left">
              <span class="info-data" v-if="showAssessment === '' || showAssessment === null">Displaying data for all Assessments</span>
              <span class="info-data" v-else>Displaying data for {{showAssessment}}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col class=".d-sm-none .d-md-none" cols="12" lg="3">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" lg="6">
          <v-card dark width="" style="border-top: 3px solid #5660a5; background-color: white">
            <v-list-item three-line>
              <v-list-item-avatar tile style="color: gray">
                <v-icon dark color="#5660a5">$vuetify.icons.values.mdiAccountGroupOutline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-1">
                  <span class="display-2" style="color: gray">{{count.TOTAL}}</span><span class="font-weight-regular" style="color: gray">
                    {{$t('msg.registered_employees_text')}}
                  </span>
                </v-list-item-title>
                <v-progress-linear value="100" color="white"></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6">
          <v-card  dark width="" style="border-top: 3px solid #5660a5; background-color: white">
            <v-list-item three-line>
              <v-list-item-avatar tile color="white">
                <v-icon dark color="#5660a5">$vuetify.icons.values.mdiFileDocument</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-1"><span class="display-2" style="color: gray">{{count.FINISHED}}</span><span class="font-weight-regular" style="color: gray"> {{$t('msg.finished_employees_text')}} </span></v-list-item-title>
                <v-progress-linear value="100" color="white"></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="12" lg="12">
          <v-card style="border-top: 3px solid #5660a5">
            <v-toolbar color="white" light flat>
              <v-icon color="blue">$vuetify.icons.values.mdiChartBar</v-icon>
              <v-toolbar-title class="ml-3">Test-Takers</v-toolbar-title>
            </v-toolbar>
            <DonutPieChart :options="pieChartData" ></DonutPieChart>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6" md="6" lg="6" v-if="spiderChartData1">
          <v-card style="border-top: 3px solid #44c1ef">
            <v-toolbar color="white" light flat>
              <v-icon color="blue">$vuetify.icons.values.mdiChartBar</v-icon>
              <v-toolbar-title class="ml-3">General Skills</v-toolbar-title>
            </v-toolbar>
            <SpiderChart :options="spiderChartData1"></SpiderChart>
          </v-card>
        </v-col>
        <v-col cols="6" md="6" lg="6" v-if="spiderChartData2">
          <v-card style="border-top: 3px solid #44c1ef">
            <v-toolbar color="white" light flat>
              <v-icon color="blue">$vuetify.icons.values.mdiChartBar</v-icon>
              <v-toolbar-title class="ml-3">Study Skills</v-toolbar-title>
            </v-toolbar>
            <SpiderChart :options="spiderChartData2"></SpiderChart>
          </v-card>
        </v-col>
      </v-row>
      <v-overlay :value="overlay">
        <v-dialog v-model="overlay" hide-overlay persistent width="300">
          <v-card color="black" dark>
            <v-card-text> {{$t('msg.loading')}}
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
import DonutPieChart from '../charts/DonutPieChart'
import SpiderChart from '../charts/SpiderChart'
import { dashboardService } from '../_services'

export default {
  components: {
    DonutPieChart,
    SpiderChart
  },
  name: 'Dashboard',
  data () {
    return {
      count: {},
      overlay: false,
      loading: false,
      showAssessment: '',
      completed: '',
      notStarted: '',
      incomplete: '',
      assessmentDropdown: [],
      organizationDropdown: [],
      filterBox: {
        assessment: '',
        organization: ''
      },
      items: [
        {
          text: 'Home',
          disabled: true,
          href: 'breadcrumbs_dashboard'
        },
        {
          text: 'Dashboard',
          disabled: true,
          href: 'breadcrumbs_link_1'
        }
      ],
      showChart: false,
      BarChart1A: {
        xAxis: { categories: [''], labels: { enabled: false } },
        yAxis: { title: { text: '' } },
        title: '',
        series: [
          {
            name: 'Registered',
            data: []
          },
          {
            name: 'Completed',
            data: []
          },
          {
            name: 'Incomplete',
            data: []
          },
          {
            name: 'Expired',
            data: []
          }],
        credits: { enabled: false }
      },
      pieChartData: {
        title: {
          text: 'All Registered Participants'
        },
        series: [
          {
            data: [
              {
                y: null,
                name: 'Not Started',
                visible: true
              },
              {
                y: null,
                name: 'Report Available',
                visible: true
              },
              {
                y: null,
                name: 'Generating Report',
                visible: true
              },
              {
                y: null,
                name: 'Incomplete',
                visible: true
              },
              {
                y: null,
                name: 'Token Expired',
                visible: true
              },
              {
                y: null,
                name: 'In Progress',
                visible: true
              }
            ]
          }
        ]
      },
      spiderChartData1: null,
      spiderChartData2: null,
      charts: []
    }
  },
  methods: {
    getDataFromApi: function () {
      this.overlay = true
      let data = {
        assessment: this.filterBox.assessment,
        organization: this.filterBox.organization
      }
      dashboardService.standDashboardHomeByAssessment(data)
        .then(response => {
          if (response.dataResponse) {
            if (response.dataResponse.count) {
              this.count = response.dataResponse.count
              this.pieChartData.series[0].data[0].y = response.dataResponse.count.NOT_STARTED
              this.pieChartData.series[0].data[0].visible = true
              this.pieChartData.series[0].data[1].y = response.dataResponse.count.FINISHED
              this.pieChartData.series[0].data[1].visible = true
              this.pieChartData.series[0].data[2].y = response.dataResponse.count.GENERATING_REPORT
              this.pieChartData.series[0].data[2].visible = true
              this.pieChartData.series[0].data[3].y = response.dataResponse.count.INCOMPLETE
              this.pieChartData.series[0].data[3].visible = true
              this.pieChartData.series[0].data[4].y = response.dataResponse.count.EXPIRED
              this.pieChartData.series[0].data[4].visible = true
              this.pieChartData.series[0].data[5].y = response.dataResponse.count.IN_PROGRESS
              this.pieChartData.series[0].data[5].visible = true

              this.notStarted = response.dataResponse.count.INCOMPLETE

              if (response.dataResponse.count.NOT_STARTED === 0) {
                this.pieChartData.series[0].data[0].visible = false
              }
              if (response.dataResponse.count.FINISHED === 0) {
                this.pieChartData.series[0].data[1].visible = false
              }
              if (response.dataResponse.count.GENERATING_REPORT === 0) {
                this.pieChartData.series[0].data[2].visible = false
              }
              if (response.dataResponse.count.INCOMPLETE === 0) {
                this.pieChartData.series[0].data[3].visible = false
              }
              if (response.dataResponse.count.EXPIRED === 0) {
                this.pieChartData.series[0].data[4].visible = false
              }
              if (response.dataResponse.count.IN_PROGRESS === 0) {
                this.pieChartData.series[0].data[5].visible = false
              }

              this.pieChartData.title = this.notStarted
              this.showChart = response.dataResponse.count.VALID
            }
            if (response.dataResponse.assessments) {
              this.assessmentDropdown = response.dataResponse.assessments
            }
            if (response.dataResponse.organizations) {
              this.organizationDropdown = response.dataResponse.organizations
            }
            this.overlay = false
            this.loading = false
          }
        }).catch(error => {
          this.loading = false
          this.overlay = false
          /* eslint-disable no-console */
          console.log('loggin response ' + error)
        /* eslint-enable no-console */
        })
    },
    filter: function () {
      for (let i = 0; i < this.BarChart1A.series.length; i++) {
        this.BarChart1A.series[i].data.pop()
      }
      this.getDataFromApi()
      if (this.filterBox.assessment) {
        this.showAssessment = this.assessmentDropdown.find(assessment => assessment.value === this.filterBox.assessment).text
      } else {
        this.showAssessment = ''
      }
    }
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  mounted () {
    if (this.$store.state.home.currentPage.trim() === '' && window.$cookies.get('sp_current_page') && window.$cookies.get('sp_current_page').trim() !== '') {
      if (window.$cookies.get('sp_current_page') === 'results') {
        this.$router.push({ name: 'results' })
      } else if (window.$cookies.get('sp_current_page') === 'bulkdownload' && this.$store.state.home.user.showExportButton) {
        this.$router.push({ name: 'bulkDownload' })
      } else if (window.$cookies.get('sp_current_page') === 'groupReports') {
        this.$router.push({ name: 'groupReports' })
      } else {
        this.getDataFromApi()
        this.$store.dispatch('home/setCurrentPage', 'Dashboard')
        this.$cookies.set('sp_current_page', 'Dashboard')
      }
    } else {
      this.getDataFromApi()
      this.$store.dispatch('home/setCurrentPage', 'Dashboard')
      this.$cookies.set('sp_current_page', 'Dashboard')
    }

    let pageTitle = ''
    if (this.$cookies.get('sp_dashboard_assessment_name') && this.$cookies.get('sp_dashboard_assessment_name').trim() !== '') {
      pageTitle = this.$cookies.get('sp_dashboard_assessment_name')
    }
    document.title = pageTitle
  }
}
</script>
<style scoped>
  .v-stepper--vertical .v-stepper__step {
    padding: 15px 25px 0px;
  }

  .cardStyle {
    border: 3px solid green;
  }

  .v-breadcrumbs {
    padding: 7px 0px 0px 0px !important;
  }

  .v-card__text, .v-card__title {
    padding: 5px;
    padding-left: 10px;
  }

  .info-data {
    font-size: 14px;
  }

  .assessment-selector {
    height: 44px;
  }
</style>
