<template>
  <div>
    <highcharts :options="chartOptions" ref="lineCharts" :constructor-type="'chart'"></highcharts>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
HighchartsMore(Highcharts)

export default {
  props: ['options', 'catchLegendEvents'],
  components: {
    highcharts: Chart
  },
  data () {
    return {
      chartOptions: Highcharts.merge(this.options, {
        colors: ['#756aa0', '#53b7a9', '#338089', '#2298dd', '#7791A4', '#50C6C1'],
        chart: {
          height: 500,
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        tooltip: {
          shared: true,
          useHTML: true,
          headerFormat: '<small>{point.key}</small><table>',
          pointFormat: '<b>{series.color} : {point.y}</b>'
        },
        plotOptions: {
          pie: {
            innerSize: '0%',
            showInLegend: true,
            dataLabels: {
              enabled: true
            }
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Math.round(this.y)
              },
              style: {
                fontSize: '18px'
              }
            }
          }
        },
        legend: {
          itemStyle: {
            font: '10pt Trebuchet MS, Verdana, sans-serif'
          }
        },
        responsive: {
          rules: [{
            condition: {
              minWidth: 200
            },
            chartOptions: {
              title: {
                verticalAlign: 'top',
                align: 'center',
                floating: false,
                style: {
                  fontSize: '16px'
                }
              },
              legend: {
                itemStyle: {
                  fontSize: '13px'
                }
              },
              subtitle: {
                verticalAlign: 'top',
                align: 'center',
                floating: false,
                style: {
                  fontSize: '20px'
                }
              }
            }
          }]
        }
      })
    }
  }
}
</script>
